export function getSlugFromUrl() {
    const path = window.location.pathname;
    const parts = path.split('/');
    return parts[parts.length - 1];
}

export function createWidgetElement(dataArray) {
    const lang = document.documentElement.lang.toLowerCase();
    const vacantText = lang === 'fi' ? 'Heti vapaa' : 'Vacant';  
    const sizeText = lang === 'fi' ? 'Koko' : 'Size';  
    const floorText = lang === 'fi' ? 'Kerros' : 'Floor';  
    const descriptionText = lang === 'fi' ? 'Asunnon kuvaus' : 'Description';  
    const wrapper = document.createElement('div');
    wrapper.className = 'apartments-wrp';

    if (!Array.isArray(dataArray) || dataArray.length === 0) {
        wrapper.innerHTML = '<p>No data available</p>';
        return wrapper;
    }

    const createApartmentItem = (data) => {
        const street = data.address.split(/\d/)[0].trim();
        return `
            <div class="apartments-item">
                <h3>${street}, ${data.city}</h3>
                
                <div class="apartments-item-info">
                    <span class="info-block">
                        <span class="info-block-title">${descriptionText}</span>
                    <span class="info-description">${data.description}</span>
                </span>
                    <div class="square">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none"><path fill="#737A8E" d="M27.586 29 11 12.414V18H9V9h9v2h-5.586L29 27.586V22h2v9h-9v-2h5.586Z"/><path fill="#737A8E" fill-rule="evenodd" d="M4 36V4h32v32H4ZM6 6h28v28H6V6Z" clip-rule="evenodd"/></svg>
                        <span class="info-block">
                            <span class="info-block-title">${sizeText}</span> 
                            ${data.sqm} м²
                        </span>                        
                    </div>
                    <div class="floor">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none"><path fill="#737A8E" d="M16 4H5v8.56l12 7.334V17.55L7 11.44V6h7v4h6v4h8v-2h-6V8h-6V4Z"/><path fill="#737A8E" fill-rule="evenodd" d="M25 16v4h-6v4h-6v4H6v8h11.281L36 24.56V16H25Zm2 2h7v5.44L16.719 34H8v-4h7v-4h6v-4h6v-4Z" clip-rule="evenodd"/></svg>
                        <span class="info-block">
                            <span class="info-block-title">${floorText}</span>
                            ${data.floor}
                        </span>
                    </div>
                </div>
            </div>
        `;
    };

    const createApartmentsBlock = () => {
        const apartmentsBlock = document.createElement('div');
        apartmentsBlock.className = 'apartments';
        apartmentsBlock.innerHTML = dataArray.map(createApartmentItem).join('');
        return apartmentsBlock;
    };

    wrapper.appendChild(createApartmentsBlock());
    wrapper.appendChild(createApartmentsBlock());

    const container = document.createElement('div');
    
    const titleText = lang === 'fi' ? 'Vapaat asunnot' : 'Available apartments';
    
    container.innerHTML = `<div class="container"><h3 class="apartments-wiget-title">${titleText}</h3></div>`;
    
    container.appendChild(wrapper);

    return container;
}
