import { fetchData } from './utils/api';
import { getSlugFromUrl, createWidgetElement } from './utils/dom';
import { applyStyles } from './utils/styles';
import '../sass/widget.sass';

(function() {
    async function initWidget() {
        try {
            // Get the slug from the URL
            const slug = getSlugFromUrl();
            
            // Load data and configuration in parallel
            const data = await fetchData(slug);

            // Check response
            if (!data || data.detail === "Apartments not found") {
                console.log("Apartments not found");
                return; 
            }
            
            // Create the widget element
            const widgetElement = createWidgetElement(data);
            
            // Apply styles to the widget
            applyStyles(widgetElement);
            
            // Add the widget to the page
            document.getElementById('apartments-widget').appendChild(widgetElement);
        } catch (error) {
            console.error('Error initializing widget:', error);
        }
    }

    // Function for safely appending the widget
    function safeAppendWidget() {
        if (document.body) {
            initWidget();
        } else {
            // If body is not yet loaded, wait and try again
            setTimeout(safeAppendWidget, 100);
        }
    }

    // Start the widget after DOM is loaded
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', safeAppendWidget);
    } else {
        safeAppendWidget();
    }
})();
